import React from "react"
import { Link } from "gatsby"
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "../../src/common/layout/layout.css"
import "@fortawesome/fontawesome-svg-core/styles.css"
import { config } from "@fortawesome/fontawesome-svg-core"
config.autoAddCss = false

const Success = props => {
  return (
    <div className="success_page">
      <div className="success_page_modal">
        <h1>Благодарим Ви!</h1>
        <p>Вашето запитване е прието!</p>
        <Link to={"/"}>
          <FontAwesomeIcon icon={faArrowLeft} />
          Обратно към нашата страница
        </Link>
      </div>
    </div>
  )
}

export default Success
